.insurance-not-verified {
  input {
    background-color: pink;
  }
  fieldset {
    border-color: #da1e28 !important;
  }
}

.insurance-verified {
  input {
    background-color: #d3e0d5;
  }
  fieldset {
    border-color: #24a148 !important;
  }
}
