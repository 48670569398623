.layout-grid {
  display: grid;
}

header {
  background-color: #061727 !important;
}

.MuiDrawer-paper {
  position: relative !important;
  background-color: #323232 !important;
  color: white !important;
}
.size-drawer-dicom {
  position: absolute !important;
  top: 0;
  height: 100%;
}
.size-dicom-view {
  position: relative !important;
  height: calc(100vh - 357px);
  height: 100%;
  width: 100%;
}

/* .cornerstone-canvas {
  width: calc(100vw - 500px) !important;
} */
.cornerstone-enabled-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
