@import "./styles/style.scss";
@import "./styles/main.scss";
@import "./styles/calendar.scss";
@import "./styles/overWrite.scss";

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.button-add-new-evaluation {
  background-color: #005ef6;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  width: auto;
  min-width: 150px;
  height: auto;
  min-height: 2.5rem;
  padding: calc(0.875rem - 3px) 12px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
  white-space: nowrap;
  cursor: pointer;
}
.CPT-AIO-css {
  height: 42px !important;
  margin-top: -1px;
  .ant-select {
    height: auto !important;
    border: none;
  }

  .ant-select-selector {
    border-bottom: none !important;
  }

  .ant-select-selection {
    height: 42px !important; /* Ensure the selected item aligns vertically */
    border: 1px solid #d9d9d9;
  }
}
/* screen capture */

/* screen capture */

.CookieConsent > div:nth-child(2) {
  display: flex !important;
  flex-direction: column !important;
}

.CookieConsent > div:nth-child(1) {
  margin: 10px 5px;
}

.CookieConsent > div:nth-child(2) button {
  display: flex !important;
  justify-content: space-between !important;
}

.crosshairs {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.error_ultrasound_finding_input {
  width: 100%;
  border: 1px solid red;
  background-color: #ff000010;
}

.ultrasound_finding_input {
  width: 100%;
  border: none;
}

.crosshairs.hidden {
  display: none;
}

.crosshairs::before,
.crosshairs::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border: none !important;
  border-image: none !important;
}

.crosshairs::before {
  left: -100%;
  top: -100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

[data-offset-key="txtporscio-0-0"] {
  [data-text="true"] {
    font-family: "MrDafoe" !important;
    font-size: 35px !important;
  }
}

.crosshairs::after {
  left: 0px;
  top: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay.highlighting {
  background: none;
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
}

.medicine-unit-suffix {
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}

.hidden {
  display: none !important;
}

.reponsive-image-chart-dcom {
  max-width: 55vw !important;
  @media (min-width: 3500px) and (max-width: 3800px) {
    max-width: 55vw !important;
  }
  @media (min-width: 3200px) and (max-width: 3500px) {
    max-width: 55vw !important;
  }
  @media (min-width: 3000px) and (max-width: 3200px) {
    max-width: 55vw !important;
  }
  @media (min-width: 2700px) and (max-width: 3000px) {
    max-width: 54vw !important;
  }
  @media (min-width: 2500px) and (max-width: 2700px) {
    max-width: 52vw !important;
  }
  @media (min-width: 2300px) and (max-width: 2500px) {
    max-width: 51vw !important;
  }
  @media (min-width: 2100px) and (max-width: 2300px) {
    max-width: 51vw !important;
  }
  @media (min-width: 1900px) and (max-width: 2100px) {
    max-width: 49vw !important;
  }
  @media (min-width: 1700px) and (max-width: 1900px) {
    max-width: 49vw !important;
  }
  @media (min-width: 1500px) and (max-width: 1700px) {
    max-width: 45vw !important;
  }
  @media (min-width: 1300px) and (max-width: 1500px) {
    max-width: 45vw !important;
  }
  @media (min-width: 1100px) and (max-width: 1300px) {
    max-width: 40vw !important;
  }
  @media (min-width: 950px) and (max-width: 1100px) {
    max-width: 35vw !important;
  }
  @media (min-width: 769px) and (max-width: 950px) {
    max-width: 30vw !important;
  }
}

.tabHeaders {
  width: 100%;
  display: flex;

  .tabHeaders-item {
    background: transparent;
    padding: 0 0 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;
    color: #152935;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    border: none;

    &.disabled {
      color: #ccc;
      cursor: not-allowed;
    }

    &.active {
      color: rgb(22, 22, 22);
      border-bottom: 3px solid rgb(15, 98, 254);
      font-weight: 600;

      &:hover {
        border-bottom-color: transparent;
      }
    }

    &:hover {
      color: rgb(57, 57, 57);
      border-bottom: 3px solid rgb(141, 141, 141);
    }

    & + .tabHeaders-item {
      // margin-left: 3rem;
    }
  }

  &.new {
    .tabHeaders-item {
      min-width: 140px;
      height: 40px;
      background: hsla(0, 0%, 86.7%, 0.2901960784313726);
      color: #152935;
      border: 1px solid #ddd;
      text-align: left;
      border-radius: 0;
      padding: 0.75rem;
      font-weight: 400;
      transition: all 1.5s ease;
      margin: 0 !important;
      user-select: none;

      &:hover {
        border: 1px solid #ddd;
      }

      &.active {
        background-color: #fff;
        border-top: 1px solid #3d70b2;
        border-bottom: 0;

        &:first-child {
          border-left: 0;
        }
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: 1112px) {
    .tabHeaders-item.active {
      border-bottom: 3px solid rgb(15, 98, 254) !important;
    }
  }
}

.public-DraftStyleDefault-ul {
  margin: 0 !important;
  padding: 0 !important;
}

.editableText.disabled > div {
  background: #d6d6d6 !important;
  border: 1px solid #d6d6d6;
}

.upload-icon {
  vertical-align: 0;
}

.upload-icon svg {
  margin-top: -2px;
  margin-right: 10px;
}

.scroll-image-list-clinic {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d6d6d6;
  padding-top: 20px;
}
