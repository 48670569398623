button.text-btn {
  background-color: transparent;
  color: #005ef6;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  width: auto;
  height: auto;
  display: inline-block;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  white-space: nowrap;
}

.div-app-new-appointment {
  display: flex;
  flex-direction: column;
  width: 100% !important;

  .fullContainer {
    .tableList {
      background-color: #fff;
    }
  }

  .tableItem {
  }
}

button.common-btn {
  background-color: rgb(61, 112, 178);
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  width: auto;
  min-width: 150px;
  min-height: 2.5rem;
  text-align: left;
  padding: calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;

  height: 2.5rem;
  width: 150px;
  background-color: #005ef6 !important;
  border: 1px solid #005ef6 !important;
  padding: 0 12px;

  &.blue-btn {
    height: 40px !important;
    width: 150px !important;
  }

  &.cancel-btn {
    background-color: transparent;
    color: #5a6872;
    width: auto;
    min-width: unset;
    padding: calc(0.875rem - 3px) 12px;
  }

  &.outlined-btn {
    background-color: #ffffff !important;
    border: 1px solid rgb(61, 112, 178) !important;
    color: rgb(61, 112, 178) !important;
  }

  &.delete-btn {
    background-color: #ca373b;
    border: 3px solid #ca373b;
    color: #fff;
    margin-right: 10px;
  }

  &[disabled] {
    background-color: #c6c6c6 !important;
    border: 1px solid #c6c6c6 !important;
  }
}

.ant-select-selection-selected-value svg {
  margin-left: 0px !important;
}

.ant-select-selection-selected-value .status-icon {
  height: 34px !important;
}

.small-blue-btn {
  background-color: #005ef6;
  color: white;
  min-width: 18px;
  min-height: 18px;
  line-height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 1px solid #005ef6;
  box-shadow: none;

  &.medium {
    min-width: 25px;
    min-height: 25px;
    line-height: 20px;
    width: 25px;
    height: 25px;
    align-self: center;
  }

  &.normal {
    min-width: 2.5rem;
    min-height: 2.5rem;
    padding: 0 4px;
  }
}

.bordered-box {
  border: 1px solid #dfe3e6;
  padding: 0.625rem 1rem;
}

.editable {
  max-width: 100%;
  outline: none !important;
  box-shadow: none !important;
  word-break: break-word;
}

hr {
  height: 0px;
}

.d-flex {
  display: flex;
  align-items: flex-end;
  &.newFlex {
    align-items: flex-start !important;
  }
}
.new-input-right-ultrasound-question {
  padding-left: 20px;
  display: flex;
  &.active {
    padding-top: 2px;
  }
}
.no-flex {
  display: block !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pr-7px {
  padding-right: 7px !important;
}

.no-border {
  border: 0 !important;
}

.min-width-35 {
  min-width: 35%;
}

.sectionTitle {
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.25;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.linksBlock {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__icon {
    margin-right: 2rem;
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      color: #0f62fe;
      line-height: 18px;
      font-size: 0.875rem;
      font-weight: 600;
    }

    p {
      color: #152934;
      padding-top: 0.5rem;
      line-height: 18px;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

.messageInfo {
  font-size: 0.875rem;
  line-height: 18px;

  p {
    padding-top: 0.5rem;
  }
}

.messageContainer {
  flex: 1;
  width: 100%;

  .messagesSection {
    width: 100%;
    height: 100%;
  }
}

.status-icon {
  svg {
    width: 0.75rem;
  }
}

.normal-icon {
  svg {
    width: 18px;
    height: 18px;
  }
}

.required-field {
  position: relative;

  .required {
    color: #da1e28;
    width: 14px;
    height: 14px;
    font-size: 12px;
    border: 2px solid #da1e28;
    border-radius: 10px;
    display: none;
    justify-content: center;
    font-weight: bold;
    line-height: 14px;
  }

  b svg {
    width: 10px;
    visibility: hidden;
  }

  &:hover {
    .required {
      display: inline-flex;
    }

    b svg {
      visibility: visible;
    }
  }
}

.barcode-Icon {
  display: inline-block;

  svg {
    width: 24px;
    height: 24px;
  }
}

.barcode-uploader {
  &.ant-upload.ant-upload-drag {
    border: 0;
    background-color: transparent;
  }

  .ant-upload.ant-upload-btn {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.rbc-toolbar-searchField.day-calendar {
  max-width: 440px;
  margin-right: 0;
  margin-left: auto !important;
}

.pal--message__icon {
  width: 4.5rem;
  height: 4.5rem;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.empty-container {
  svg {
    width: 70px;
  }
}

.MuiInputAdornment-positionStart {
  margin-right: 0px !important;
}

.td.with-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 0.75rem;
    margin-left: 0px;
    width: 16px;
    min-width: 16px;
    height: auto;
    vertical-align: middle;
  }
}

.ant-input {
  &::placeholder {
    font-family: "IBM plex sans", "Helvetica Neue", sans-serif !important;
  }
}

.rightSearchField {
  position: relative;

  .ant-input-search.ant-input-affix-wrapper {
    display: flex;

    input {
      font-size: 0.875rem;
      height: 2.5rem;
      border: none !important;
      border-radius: 0;
      box-shadow: none;
      outline: none;
      color: #152935;
      font-weight: 400;
      padding: 0 2.5rem;
      text-overflow: ellipsis;
      width: 100%;
      order: 1;
      flex: 1;

      &::placeholder {
        color: #5a6872d1;
        font-weight: 400;
      }
    }

    .ant-input-suffix {
      left: 0.75rem;
      right: unset;
      order: -1;
      flex: 0;

      i {
        display: none;
      }

      svg {
        fill: #393939;
        height: 16px;
        width: 16px;
      }
    }
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.safari-browser {
  .ol-ul {
    ol,
    ul {
      padding-left: 18px !important;
    }
  }
}

.no-safari {
  .ol-ul {
    ol,
    ul {
      padding-left: 15px !important;
    }
  }
}

.closeMessage {
  &.selected {
    svg {
      fill: #ca373b;
    }

    .a-close {
      fill: #ca373b !important;
    }
  }
}

.sortableHeader {
  &.resourceInfo {
    background-color: #fff;
    padding: 0 !important;
    margin: 0 0.5rem !important;
    width: calc(100% - 1rem) !important;
    border-bottom: 1px solid #dfe3e6;
  }

  .th.sortable {
    height: 48px;
    border-left: 0px solid #dfe3e6;
    align-items: center !important;
  }
}

.td .iconField {
  display: flex;
  min-width: 24px;
}

.tableContent {
  flex: 1;
  width: 100%;
  margin-top: 1rem;

  .tableHeader {
    position: sticky;
    top: 0;
    background: #f4f7fb;
    min-width: 1320px;
    margin-top: 0 !important;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #dfe3e6;
  }
}

.th b {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

.th.sortable {
  position: relative;
  padding-right: 0.5rem;
  font-weight: 600;

  .ant-select-selection.ant-select-selection--single {
    padding-left: 12px !important;
  }

  .ant-select-selection__placeholder {
    color: #152934 !important;
    font-weight: 600 !important;
    font-family: "IBM plex sans", "Helvetica Neue", sans-serif !important;
  }

  .ant-time-picker .ant-time-picker-input {
    box-shadow: none;
    background: transparent;
    padding-right: 1rem;

    &::placeholder {
      color: #152934 !important;
      font-weight: 600 !important;
      font-family: "IBM plex sans", "Helvetica Neue", sans-serif !important;
    }

    & + svg {
      display: none;
    }
  }

  .ant-calendar-picker-input.ant-input {
    background: transparent;
    padding-right: 1rem;

    & + svg {
      display: none;
    }

    &::placeholder {
      color: #152934 !important;
      font-weight: 600 !important;
      font-family: "IBM plex sans", "Helvetica Neue", sans-serif !important;
    }
  }

  &:first-child {
    margin-left: 0rem;
  }

  b {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sortIcons {
    position: absolute;
    right: 8px;
    top: 0;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;

    .up {
      height: 14px;
      color: #7d7d7d;

      &.active {
        color: rgb(61, 112, 178);

        svg {
          fill: rgb(61, 112, 178);
        }
      }

      svg {
        fill: #7d7d7d;
        transform: rotate(180deg);
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }

    .down {
      height: 14px;
      color: #7d7d7d;

      &.active {
        color: rgb(61, 112, 178);

        svg {
          fill: rgb(61, 112, 178);
        }
      }

      svg {
        fill: #7d7d7d;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
  }

  &.hide-icon {
    svg {
      display: none;
    }

    &:hover svg {
      display: inline-block;
    }
  }

  & > svg {
    align-self: center;
    height: 22px;
    width: auto;
    margin-left: 0;
  }

  &:hover {
    background: #dbe3ee;

    input {
      background: transparent !important;
      transition: none !important;
    }
  }

  .ant-select-selection.ant-select-selection--single {
    padding-right: 0 !important;
  }
}

p {
  margin-bottom: 0px !important;
  color: #152935;
}

.emptyContentIcon {
  svg {
    width: 70px;
  }
}

.oneLineText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.documentCategory {
  margin-top: 0.5rem;

  &:first-child {
    margin-top: 0;
  }
}

.documentArrowIcon {
  svg {
    width: 25px;
    fill: #005ef6;
  }
}

.pannel-title {
  svg {
    width: 18px;
    height: 26px;
    vertical-align: bottom;
  }
}

.tableList {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;

    .ant-collapse-arrow {
      left: 8px;
    }

    .tableItem {
      font-weight: normal;
    }

    .tableItem > div:first-child {
      padding-left: 32px;
    }
  }

  .ant-collapse > .ant-collapse-item .ant-collapse-content-active {
    background-color: #dbe3ee;
  }
}

.fullContainer {
  .patientSearch {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0;

    &__filter {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      &-search {
        flex: 1;
        height: 2.5rem;
        border: 1px solid #dfe3e6;
        border-right: 0;
        background-color: #f4f7fb;

        .ant-input-affix-wrapper {
          display: flex;
          height: 100%;

          input {
            font-size: 0.875rem;
            height: 100%;
            border: none !important;
            border-radius: 0;
            box-shadow: none !important;
            outline: none;
            background-color: #f4f7fb;
            color: #152935;
            font-weight: 400;
            padding: 0 2.5rem;
            text-overflow: ellipsis;
            width: 100%;
            order: 1;
            flex: 1;

            &::placeholder {
              color: #5a6872d1;
              font-weight: 400;
            }
          }

          .ant-input-suffix {
            left: 0.75rem;
            right: unset;
            order: -1;
            flex: 0;

            i {
              display: none;
            }

            svg {
              fill: #5a6872d1;
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      &-action {
        height: 2.5rem;
        width: 120px;
        background-color: #005ef6;
        border: 2px solid #005ef6;
        padding: 0 12px;

        @media (max-width: 768px) {
          width: 90px;
        }

        &.disabled {
          background-color: #c6c6c6;
        }
      }

      &-add {
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #005ef6;
        color: #ffffff;
        font-size: 0.875rem;
        font-weight: 600;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        outline: none;
        border: none;
        box-shadow: none;
        cursor: pointer;
      }
    }

    &__result {
      position: absolute;
      top: 41px;
      left: 0;
      width: 80%;
      min-width: 340px;
      max-width: 520px;
      height: calc(100% - 80px);
      max-height: 210px;
      background-color: #ffffff;
      z-index: 20;
      border: 1px solid #7d7d7d4a;

      .tableContainer {
        .tableItem {
          border-top: 0 !important;
        }

        .tableItem:last-child {
          border-bottom: 0 !important;
        }
      }
    }

    .tableContainer {
      width: 100%;
      display: block;

      .tableItem:first-child {
        border-top: 0;
      }
    }
  }
}

.tableList {
  .patientSearch__filter-search {
    border: 0;
    height: auto;

    input {
      border-bottom: 0px solid #8d8d8d !important;
      box-shadow: none !important;
    }
  }
}

.check-status-icon {
  width: 16px;
  height: auto;
  display: inline-block;

  svg {
    width: 16px;
    height: 16px;
  }
}

.mention-suggestion {
  width: 0px;
  height: 0px;
  display: inline-block;

  & > div {
    max-height: 180px;
    overflow-y: auto;
  }
}

.editor-toolbar-horizontal {
  & > div > div {
    display: flex;
  }
}

.custom-toolbar-editor {
  .editor-custom-toolbar {
    display: flex;
    padding: 6px 5px 0;
    margin-bottom: 5px;
    border: 1px solid #f1f1f1;
    border-radius: 0;
    flex-wrap: wrap;
    background: #fff;
    user-select: none;
    font-size: 15px;

    div > button {
      border: 1px solid #f1f1f1;
      padding: 0 5px 1px;
      min-width: 25px;
      height: 20px;
      border-radius: 2px;
      margin: 0 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #fff;
      text-transform: capitalize;
      margin-bottom: 6px;

      &:hover {
        box-shadow: 1px 1px 0px #bfbdbd;
      }

      svg {
        height: 20px;
        min-width: 17px;
      }
    }
  }
}

.side-component > div > div {
  position: relative;
}

.side-component > div > div > div {
  position: absolute;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600 !important;
  font-size: 0.625rem !important;

  span {
    font-weight: 600 !important;
    font-size: 0.625rem !important;
    box-sizing: border-box;
    display: inline-block;
  }
}

.dropdown-button svg {
  width: 12px;
  margin-left: 0 !important;
}

.cardBlock__content .DraftEditor-root {
  height: 100%;
}

.show-mobile {
  display: none;
}

.multi-select {
  --rmsc-radius: 0;

  .dropdown-heading svg {
    stroke: #161616;
    margin-left: 0px !important;
    stroke: #161616;
    stroke-width: 1.5px;
  }
  .dropdown-heading {
    padding: 10px;
  }
  .item-renderer {
    margin-top: 5px;
  }

  .dropdown-content {
    background-color: #fff !important;
    padding: 10px !important;
    border: 1px solid #d9d9d9;
  }
}

.ant-collapse-content.ant-collapse-content-active {
  overflow: unset;
}

.perfect-scrollbar {
  & > div:first-child {
    padding: 0 16px 16px;
  }

  & > div:last-child {
    & > div {
      background-color: rgba(0, 0, 0, 0.4) !important;

      @media (min-width: 1112px) {
        transition: opacity 0.1s linear;
      }
    }
  }

  &.no-padding {
    & > div:first-child {
      padding: 0 0 16px;
    }
  }

  &.no-padding-admin {
    & > div:first-child {
      padding: 0 0 16px;
    }
  }

  &.no-padding-table {
    // & > div:first-child {
    //   padding: 0 0 0px;
    // }
  }

  &.no-padding-new-appointment {
    & > div:first-child {
      padding: 0 0 16px;
    }
  }

  &.no-padding-plan {
    & > div:first-child {
      padding: 0 0 16px;
    }
  }

  .no-padding-icd {
    & > div:first-child {
      padding: 0 0 16px;
    }
  }

  &.no-x-padding {
    & > div:first-child {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 16px;
      padding-top: 0;
    }
  }

  &.x-hidden {
    & > div:first-child {
      overflow-x: hidden !important;
      padding-bottom: 0;
    }
  }

  &.x-hidden-new-appointment {
    & > div:first-child {
      overflow-x: hidden !important;
      padding-bottom: 0;
    }
  }

  &.x-hidden-plan {
    & > div:first-child {
      overflow-x: hidden !important;
      padding-bottom: 0;
    }
  }

  &.conditionList {
    & > div:first-child {
      background-color: #ffffff;
      text-align: left;
      padding: 1rem 2rem;
    }
  }

  &.scrolling {
    & > div:last-child {
      & > div {
        opacity: 1;
      }
    }
  }

  @media (min-width: 1440px) {
    &.tab-header-scroll > div {
      position: relative !important;
    }
  }
}

.dropdown-btn-container {
  position: relative;
  z-index: 2;

  .dropdown-btn {
    min-height: 2.5rem;
    padding: calc(0.875rem - 3px) 12px;
    line-height: 1;
    border: 3px solid rgba(0, 0, 0, 0);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #3d70b2;
    color: #fff;
    font-weight: 400;
  }

  .dropdown-overlay {
    position: absolute;
    z-index: 3;
    right: 0;
    padding: 4px 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5;
    font-size: 14px;
    display: none;
    margin-top: 4px;

    .reason {
      cursor: pointer;
      line-height: 22px;
      padding: 5px 12px;
      transition: background 0.3s ease;
      white-space: nowrap;
      color: #152935;

      &:hover {
        background-color: #dbe3ee;
      }
    }
  }

  &.opened {
    .dropdown-overlay {
      display: block;
    }
  }
}

@media (max-width: 1179px) {
  .hide-tablet {
    display: none !important;
  }
}

@media (max-width: 555px) {
  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none !important;
  }
}
