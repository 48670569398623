@font-face {
  font-family: "MrDafoe";
  src: url("/fonts/MrDafoe-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "IBM plex sans";
  src: url("/fonts/IBMPlexSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "IBM plex sans";
  src: url("/fonts/IBMPlexSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "IBM plex sans";
  src: url("/fonts/IBMPlexSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "IBM plex sans";
  src: url("/fonts/IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "IBM plex sans";
  src: url("/fonts/IBMPlexSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "IBM plex sans";
  src: url("/fonts/IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

html {
  overflow-y: hidden !important;
}

body {
  margin: 0;
  font-family: "IBM plex sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
input,
p,
div,
button,
span {
  font-family: "IBM plex sans", "Helvetica Neue", sans-serif !important;
}

input {
  text-align: left;
}

input,
textarea {
  color: #152935 !important;
}
input::placeholder,
textarea::placeholder {
  color: #5a6872d1 !important;
  font-weight: 400 !important;
}

.ant-input-affix-wrapper .ant-input {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.ant-select-dropdown-menu-item {
  color: #152935 !important;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
}

::-webkit-scrollbar:track:horizontal{
  height: 8px;
  /* background-color: red; */
}
::-webkit-scrollbar-thumb:horizontal{
        /* background: orange; */
        border-radius: 0;
        
    }

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-left: 0px solid rgba(250, 250, 250, 0);
  border-right: 0px solid rgba(250, 250, 250, 0);
  background: rgba(250, 250, 250, 0);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: rgba(250, 250, 250, 0);
  border: 0px solid rgba(250, 250, 250, 0);
  -webkit-box-shadow: none;
}
::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: none;
}
.phoneView {
  display: flex;
}
.player-control {
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.player-control-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  margin-top: 10px;
}
.video-size-dicom {
  background-color: black;
  position: relative;
  width: 100%;
  height: calc(100% - 85px);
}
.react-player {
  position: absolute;
  top: 0px;
  left: 0px;
  max-height: calc(100% - 0px);
}
.size-dicom-default-view {
  position: relative !important;
  height: calc(100vh - 357px);
  width: 100%;
}
.image-dicom {
  height: 80px;
  width: 100px;
  -webkit-background-size: contain; /* For WebKit*/
  -moz-background-size: contain; /* Mozilla*/
  -o-background-size: contain; /* Opera*/
  background-size: contain;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100px;
  min-width: 100px;
  max-height: 80px;
  min-height: 80px;
}

@media (min-width: 830px) {
  .phoneView {
    display: none !important;
  }
}
/* pause */
.play-pause {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 16px;
  border-left: 4px solid;
  border-right: 4px solid;
}
/* play */
.button-video-play-pause {
  /* margin-left: 100px; */
}
.play-button {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 12px;
  height: 16px;
}
.play-button::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 16px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 11px solid;
  top: 0px;
  left: 2px;
}
/* forward */
.button-forward {
  margin-left: 50px;
}
.play-forwards {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 16px;
  background: currentColor;
}
.play-forwards::after,
.play-forwards::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 16px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid;
  left: -10px;
}
.play-forwards::before {
  left: -20px;
}
/* backward */
.button-video-back {
  margin-right: 50px;
}
.play-backwards {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 6px;
  height: 16px;
  background: currentColor;
}
.play-backwards::after,
.play-backwards::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 16px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 10px solid;
  right: -10px;
}
.play-backwards::before {
  right: -20px;
}
