.login_container {
  display: flex;
  height: 100%;
}
.image_container {
  width: 50%;
}
.image_container img {
  width: 100% !important;
  object-fit: cover;
  margin: 0 !important;
  height: 100vh;
}
.logo_container {
  margin-bottom: 5rem;
  width: 280px;
}
.logo_container img {
  margin-left: -27px !important;
  margin-bottom: 0 !important;
  width: 100%;
}
.login_form_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 5rem;
  width: 50%;
}
.login_form_child_container {
  max-width: 800px;
  min-width: 600px;
  max-height: 800px;
  min-height: 600px;
  margin: auto;
}

.formContainer__heading h1 {
  text-align: left;
  font-size: 3rem;
  font-weight: bolder;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.formContainer__heading p {
  margin: 1.5rem 0;
  margin-bottom: 2.5rem !important;
}
.login_form {
  max-width: 400px;
  min-width: 300px;
}
.login_form_button {
  width: 100%;
  height: 40px !important;
  background: #005ef6 !important;
}
.login_form_button span{font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;}
.submit_container {
    margin-top: 10px;
  margin-bottom: 10px !important;
}
.form_label label{font-weight: bolder;}
.form_label > div{font-family: sans-serif !important;}

.loginInputs {
    height: 40px !important;
    border-radius: 0 !important;
}
.login_form_container input::placeholder{opacity: 1 !important;}
@media only screen and (min-width: 1500px) {
  .login_form_container {
    margin-top: 4rem;
  }
}
