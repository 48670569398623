.ExaminationROSItem {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #dfe3e6;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  &__box {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 25%;
    transform: translate(0%, 100%);
    width: 70%;
    max-width: 70%;
    min-height: 80px;
    outline: none;
    box-shadow: none;
    border: 2px solid #dbe3ee;
    border-radius: 0;
    background-color: #ffffff;
    color: #152935;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 !important; //
    word-break: break-word;

    & > div > svg {
      fill: #152935 !important;
      width: 10px;
    }

    textarea {
      border: none;
      border-radius: 0;
    }
  }

  &__label {
    width: 30%;
    min-width: 180px;
    max-width: 100%;
    color: #152935;
    font-size: 12px;
    font-weight: 800;
    white-space: normal;
    text-overflow: unset;
    text-align: left;
  }
  &__icon {
    width: 2rem;
    height: 2rem;
    color: #152935;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 8px;
    z-index: 5;

    &.open {
      color: #0f62fe;

      i svg {
      }
    }

    i {
      width: 100%;
      height: 100%;
      padding: 4px;
      fill: #0f62fe;

      svg {
        width: 100%;
        height: 100%;
        fill: #152935;
      }
    }
  }
  &__options {
    flex: 1;
    padding-left: 48px;
    min-width: 288px;
    max-width: 100%;
    position: relative;
    flex-wrap: wrap;
    display: flex;
    gap: 0.5rem;

    &.checkbox {
      flex-direction: column;
    }
  }

  &__btnContainer {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__option {
    height: 2rem;
    background-color: #f4f7fb;
    border: 1px solid #dfe3e6;
    flex: 1;
    min-width: 125px;
    max-width: 125px;
    padding: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #152935;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.required {
      color: #152935;
      border: 1px solid red;
      background: #ff000010;
    }

    &.active {
      color: #152935;
      border: 1px solid #0f62fe;
      background: #dbe3ee;

      &:hover {
        color: #152935;
        border: 1px solid #0f62fe;
        background-color: #dbe3ee;
      }
    }
  }

  &__option.not-locked {
    &:hover {
      border: 1px solid #0f62fe;
      background-color: #dbe3ee;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  input {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    max-width: 200px;
    outline: none;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: #dfe3e6;
      outline: none;
      box-shadow: none;
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;

    &__options {
      flex-wrap: wrap;
    }
    &__option {
      margin-bottom: 8px;
      min-width: 90px;
    }

    &__box {
      left: 10px;
      width: calc(100% - 10px);
      max-width: calc(100% - 10px);

      .a-close {
        fill: #0f62fe;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .ExaminationROSItem {
    flex-wrap: wrap !important;
    &__label {
      width: 100% !important;
    }
  }
}
