.atuoAccordion {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__filter {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    &-result {
      position: absolute;
      z-index: 12;
      left: 0;
      top: 0;
      height: 200px;
      transform: translateY(42px);
      width: calc(100% - 120px) !important;
      border: 1px solid #dfe3e6;
      max-width: 560px;

      &-list {
        position: relative;
        background-color: #ffffff;
        height: 100% !important;
        border-bottom: 0 !important;
      }

      .atuoComplete__result-item {
        border: 0 !important;
      }
    }

    &-search {
      flex: 1;
      background-color: #f4f7fb;
      border: 0;

      &.white {
        .ant-input-search.ant-input-affix-wrapper input {
          background-color: #fff;
        }
      }

      .ant-input-search.ant-input-affix-wrapper {
        display: flex;

        input {
          font-size: 0.875rem;
          height: 2.5rem;
          border: 1px solid #dfe3e6 !important;
          border-radius: 0;
          box-shadow: none !important;
          outline: none;
          background-color: #f4f7fb;
          color: #152935;
          font-weight: 400;
          padding: 0 2.5rem;
          text-overflow: ellipsis;
          width: 100%;
          order: 1;
          flex: 1;

          &::placeholder {
            color: #5a6872d1;
            font-weight: 400;
          }
        }

        .ant-input-suffix {
          left: 0.75rem;
          right: unset;
          order: -1;
          flex: 0;

          i {
            display: none;
          }

          svg {
            fill: #5a6872d1;
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    &-action {
      height: 2.5rem;
      width: 120px;
      background-color: #005ef6;
      padding: 0 12px;

      @media (max-width: 768px) {
        width: 90px;
      }

      &.disabled {
        background-color: #c6c6c6;
      }
    }

    &-add {
      padding: 0;
      margin: 0;
      width: 100%;
      background-color: #005ef6;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 600;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      outline: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }
  }

  &__result {
    width: 100%;
    margin-top: 1.5rem;
    height: calc(100% - 4rem);
    overflow-x: hidden;
    overflow-y: auto;

    &-list {
      width: 100%;
      height: 100%;

      .ant-collapse-extra {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &-item {
      &-close_btn {
        display: inline-flex;
        margin-right: 0.5rem;
        cursor: pointer;
        width: 22px;
        height: 22px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .content {
    padding-left: 0.6rem;
    position: relative;

    &__editbox {
      position: absolute;
      z-index: 10;
      bottom: -1.4rem;
      left: 35%;
      min-height: 80px;
      width: calc(65% - 0.5rem);
      max-width: 342px;
      outline: none;
      box-shadow: none;
      border: 1px solid #dfe3e6;
      border-radius: 0;
      background-color: #ffffff;
      color: #152935;
      font-size: 0.875rem;
      text-align: left;
      padding: 0;
      word-break: break-word;
    }
  }

  .ant-collapse-content-active {
    overflow: unset;
  }
}

.clinicWorkflow__main .eventsContainer .patientContainer .detailSection .ant-collapse.ant-collapse-icon-position-left {
  overflow-y: visible !important;
}

.clinicWorkflow__main-section .perfect-scrollbar.hpi_container {
  height: 100% !important;
}

.HPIItem {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #dfe3e6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  &.checkbox {
    align-items: flex-start;
  }

  &__box {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 25%;
    transform: translate(0%, 100%);
    width: 70%;
    max-width: 70%;
    min-height: 80px;
    outline: none;
    box-shadow: none;
    border: 2px solid #dbe3ee;
    border-radius: 0;
    background-color: #ffffff;
    color: #152935;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 !important; //
    word-break: break-word;

    .a-close {
      fill: #152935;
    }

    & > div > svg {
      fill: #152935 !important;
      width: 10px;
    }

    textarea {
      border: none;
      border-radius: 0;
    }
  }

  &__label {
    width: 100%;
    max-width: 35%;
    color: #152935;
    font-size: 12px;
    font-weight: 800;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    color: #152935;
    cursor: pointer;

    &.open {
      color: #0f62fe;

      i svg {
        fill: #0f62fe;
      }
    }

    i {
      width: 100%;
      height: 100%;
      padding: 4px;

      svg {
        width: 100%;
        height: 100%;
        fill: #152935;
      }
    }
  }

  &__options {
    flex: 1;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.checkbox {
      flex-direction: column;
    }
  }

  &__option {
    height: 2rem;
    background-color: #f4f7fb;
    border: 1px solid #dfe3e6;
    margin-right: 0.5rem;
    flex: 1;
    max-width: 98px;
    padding: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #152935;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 0;
    outline: none;
    box-shadow: none;

    &.active {
      color: #152935;
      border: 1px solid #0f62fe;
      background: #dbe3ee;

      &:hover {
        color: #152935;
        border: 1px solid #0f62fe;
        background-color: #dbe3ee;
      }
    }
    &.hover-show {
      border: 1px solid #f1c21b;
      background-color: #fffee8;
    }
    &.hover-green-show {
      border: 1px solid #6fdc8c;
      background-color: #defbe6;
    }
    &.hover-delete {
      border: 1px solid red;
      background-color: #ff000010;
    }
    &.not-locked {
      &:hover {
        border: 1px solid #0f62fe;
        background-color: #dbe3ee;
      }
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  input {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    max-width: 200px;
    outline: none;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: #dfe3e6;
      outline: none;
      box-shadow: none;
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;

    &__options {
      flex-wrap: wrap;
      padding-left: 0;
    }

    &__option {
      margin-bottom: 8px;
      min-width: 90px;
    }

    &__box {
      left: 10px;
      width: calc(100% - 10px);
      max-width: calc(100% - 10px);
    }
  }
}
