.hover-table {
  width: 100%;
  border-collapse: collapse;
}

.hover-table caption {
  caption-side: top;
  text-align: center;
}

.hover-table th {
  text-align: center;
  border: #999999 1px solid;
  background-color: #d9d9d9;
}

.hover-table td {
  padding: 3px;
  border: #999999 1px solid;
  text-align: center;
}

.hover-table tr td:first-child {
  text-align: left;
  font-weight: bold;
  width: 25%;
  padding-left: 10px;
}

.hover-table tr td:nth-child(2) {
  width: 15%;
}

.hover-table tr td:nth-child(3) {
  width: 15%;
}

.hover-table tr td:nth-child(4) {
  width: 15%;
}

.hover-table tr td:nth-child(5) {
  width: 15%;
}

.hover-table tr td:nth-child(6) {
  width: 15%;
}

/* Define the default color for all the table rows */
.hover-table tr {
  background: #fff;
}

/* Define the hover highlight color for the table row */
/* .hover-table tr:hover {
  background-color: #5a6268;
  color: #fff;
} */
