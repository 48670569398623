.fullContainer .tableContainer.double .tableList .tableItem {
  height: 5rem;
}

.fullContainer .tableContainer.double .tableList .tableItem p {
  font-size: 1.75rem;
}
.fullContainer .tableContainer.double .tableList .tableItem .td .iconField {
  min-width: 34px;

  svg {
    width: 24px;
  }
}
