.error {
    .ant-select-selection__placeholder {
        color: var(--error-color) !important;
    }

    .ant-select {
        background: rgba(238, 0, 0, 0.1) !important;
    }
}

.vascuqol .ant-select-selection {
    height: 100% !important;
}