.panel_snap {
  padding: 0px;
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-header {
    padding: 10px 0px 10px 0px !important;
    &[aria-expanded="true"] {
      background-color: #f2f2f2 !important;
    }
  }

  &.clinicWorkFlow_panel {
    .ant-collapse-header {
      padding: 10px 0px 10px 0px !important;
      &[aria-expanded="true"] {
        background-color: #F4F7FB  !important;
      }
    }
  }
}
.snapList {
  width: 100%;
  .snapItem {
    cursor: pointer;
    &.selected {
      background-color: #f2f2f2;
      .snapDetail__status {
        &.red {
          div {
            color: white;
            width: 25px;
            height: 25px;
            line-height: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(224, 24, 45);
          }
        }
        &.yellow {
          div {
            color: white;
            width: 25px;
            height: 25px;
            line-height: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(239, 193, 0);
          }
        }
      }
    }

    .snapDate {
      width: 100%;
      max-width: 30%;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: 400;
      color: #152934;
    }
    .snapDetail {
      width: 100%;
      max-width: 100%;
      padding-right: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &__status {
        div {
          border: 2px solid transparent;
          width: 1.5rem;
          height: 1.5rem;
          flex: 0;
          border-radius: 0.875rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.5rem;
        }
        &.red {
          div {
            color: rgb(224, 24, 45);
            border-color: rgb(224, 24, 45);
          }
        }
        &.yellow {
          div {
            color: rgb(239, 193, 0);
            border-color: rgb(239, 193, 0);
          }
        }
      }
      &__content {
        flex: 1;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &-block {
          display: block;
        }
        &-title {
          width: 100%;
          text-align: left;
          font-weight: 600;
          line-height: 1.5rem;
          color: #152934;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
        &-info {
          width: 100%;
          margin-top: 0.5rem;
          text-align: left;
          font-weight: 400;
          color: #152934;
        }
        .description {
          display: block;
        }
      }
    }

    @media (max-width: 540px) {
      flex-direction: column;

      .snapDetail {
        max-width: 100%;
        padding-top: 10px;
      }
    }
  }
}
