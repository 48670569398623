.ant-select {
  position: relative;
  width: 40%;
  margin: 0 4px;
  height: 2rem;
  max-height: 2rem;
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid rgb(141, 141, 141);
  cursor: pointer;
  color: #152935;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.ant-checkbox-inner {
  border: 1px solid #8d8d8d;
}

.ant-select-search--inline .ant-select-search__field {
  box-shadow: none !important;
  border-radius: 0;
}

.ant-select-selection.ant-select-selection--single {
  font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0;
  background: none;
  appearance: none;
  border: 0;
  width: 100%;
  position: relative;
  height: 100%;
  cursor: pointer;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  padding: 0 1rem;
  color: #152935;
  box-shadow: none;
  order: 2;
  border: none;
  border-bottom: none;
  border-radius: 0;

  .ant-select-selection__rendered {
    width: 100%;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;

    .ant-select-search__field {
      padding: 0;
    }
    .ant-select-selection-selected-value {
      margin-bottom: 2px;
      width: 100%;
      padding-right: 4px;
    }
  }
  .ant-select-arrow {
    flex: 0;
    transform: translateY(-50%);
    margin-top: 2px;

    svg {
      fill: rgb(22, 22, 22);
      transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
    }
  }
  .ant-select-selection__placeholder {
    color: #5a6872d1;
    font-weight: 400;
  }
}

.ant-time-picker {
  svg {
    fill: rgb(22, 22, 22);
    margin-left: 4px !important;
  }

  .ant-time-picker-input {
    font-size: 0.875rem;
    height: 2.5rem;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: #152935;
    font-weight: 400;
    padding: 0 2.5rem 0 1rem;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: #5a6872d1;
      font-weight: 400;
    }
  }
}

.ant-input-number {
  color: #152935;
  border-radius: 0;
  border: 0;
  outline: none;
  box-shadow: none;

  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input-wrap {
    outline: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.ant-calendar-picker {
  position: relative;
  box-shadow: none;
  border-bottom: 1px solid rgb(141, 141, 141);

  svg {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    fill: rgb(22, 22, 22);
    transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
  }

  .anticon.anticon-close-circle.ant-calendar-picker-clear {
    right: 30px;
    top: 50%;
    transform-origin: 50% 50%;

    svg {
      position: unset;
      transform-origin: unset;
    }
  }
}

.ant-calendar-picker-input.ant-input {
  font-size: 0.875rem;
  height: 2.5rem;
  border: none !important;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: #152935;
  font-weight: 400;
  padding: 0 2.5rem 0 1rem;
  text-overflow: ellipsis;
  width: 100%;

  &::placeholder {
    color: #5a6872d1;
    font-weight: 400;
  }
}

.ant-collapse-extra {
  svg {
    width: 10px;
    height: 20px;
  }
}

.anticon.anticon-close-circle.ant-calendar-picker-clear {
  display: none;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  @media (max-width: 540px) {
    margin-left: 0;
  }
}

.ant-collapse.ant-collapse-icon-position-left {
  border: none;
  .ant-collapse-header {
    text-align: left;
    font-weight: 800;
    background-color: #ffffff;
    padding-left: 32px;

    .ant-collapse-arrow {
      left: 8px;
    }
  }
}

.ant-select-dropdown--single {
  .ant-select-dropdown-menu-item-active {
    background-color: rgb(229, 229, 229);
  }
  .ant-select-dropdown-menu-item:hover {
    background-color: rgb(229, 229, 229);
  }
}

.ant-select-dropdown--single .ant-select-dropdown-menu-item-active,
.ant-select-dropdown--single .ant-select-dropdown-menu-item:hover {
  background-color: #dbe3ee !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #dbe3ee !important;
}
